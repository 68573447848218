.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  // height: 100%;
  // justify-content: space-between;
}

// scrollbar-width: none;
// -ms-overflow-style: none;
// ::-webkit-scrollbar {
//   display: none; /* Chrome Safari */
// }
