.content {
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 40;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  text-align: left;
}

.test {
  position: relative;
  width: 100%;
  height: 100%;
}
