.glitch,
.glitch::after {
  width: 100%;
  height: 100%;
  position: absolute;
  text-wrap: nowrap;
  white-space: nowrap;
  top: 0rem;
  left: 0rem;
  color: #2dcf77;
  position: relative;
  font-weight: 700 !important;
  font-family: "HarmonyBold", Arial, sans-serif;
  animation: transform 7s infinite, glitch 8s infinite;
  animation-timing-function: step-end;
  text-align: center;
}

.glitch::after {
  position: absolute;
  top: 0;
  left: 0;
  content: attr(data-text);
  white-space: pre;
  color: #ffff13;
}

@keyframes transform {
  0% {
    transform: translate(-5%, -5%) skew(-10deg) scale(1.05);
  }
  1% {
    transform: none;
  }
  88% {
    transform: none;
  }
  89% {
    transform: translate(5%, 10%) scale(1.05);
  }
  90% {
    transform: none;
  }
}

@keyframes glitch {
  45% {
    filter: none;
  }
  46% {
    filter: url(#distort-1);
  }
  47% {
    filter: url(#sharp-distort);
  }
  48% {
    filter: url(#distort-2);
  }
  50% {
    filter: none;
  }
  95% {
    filter: none;
  }
  96% {
    filter: url(#sharp-distort);
  }
  97% {
    filter: url(#distort-2);
  }
}

// codepen presentational stuff
