.overlay {
  position: fixed;
  top: 0;
  left: 0;
  grid-area: 1 / 1 / 2 / 2;
  z-index: 1000;
  pointer-events: none;
  width: 100vw;
  height: 100vh;
}
