// .canvas {
//   display: block;
//   width: 100vw !important;
//   height: 80% !important;
// }
.filters-background {
  background-image: linear-gradient(
    transparent 0%,
    rgba(71, 94, 71, 0.832) 100%
  );
  background-size: 1000px 2px;
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  z-index: 1;
}
.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* 全屏背景 */
  overflow: hidden;
}

.video-background .video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: 2; /* 确保视频在其他内容后面 */
}

.timelineBox {
  position: relative;
  z-index: 11 !important;
  width: 100svw;
  height: 100svh;
  overflow-y: scroll;
}
h3 {
  font-size: 1.2rem;
  font-weight: 700;
}
$color-1: white;
$color-2: black;
$color-3: #33ba2e;

/* Styling */
.timeline {
  margin: 4em auto;
  position: relative;
  width: 100%;
  // border: 1px solid red;
  text-align: center;
  &:before {
    background-color: $color-1;
    content: "";
    margin-left: -1px;
    position: absolute;
    top: 0;
    left: 2em;
    width: 2px;
    height: 100%;
  }
}

.timeline-event {
  position: relative;

  &:hover {
    .timeline-event-icon {
      transform: rotate(-45deg);
      background-color: $color-3;
    }

    .timeline-event-thumbnail {
      box-shadow: inset 50em 0 0 0 $color-3;
    }
  }
}

.timeline-event-copy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em 1em;
  position: relative;
  top: -1.875em;
  left: 4em;
  width: 80%;

  h4 {
    font-size: 1.2em;
    margin-bottom: 1.2em;
  }

  strong {
    font-weight: 700;
  }

  p:not(.timeline-event-thumbnail) {
    padding-bottom: 1.2em;
  }
}

.timeline-event-icon {
  transition: transform 0.2s ease-in;

  background-color: $color-1;
  outline: 8px solid $color-2;
  display: block;
  margin: 0.5em 0.5em 0.5em -0.5em;
  position: absolute;
  top: 0;
  left: 2em;
  width: 1em;
  height: 1em;
}

.timeline-event-thumbnail {
  transition: all 0.2s ease-in;
  color: $color-2;
  font-size: 1em;
  white-space: nowrap;
  font-weight: 600;
  background-color: $color-1;
  display: inline-block;
  margin-bottom: 1.2em;
  padding: 0.25em;
}

@media screen and (min-width: 1024px) {
  // .canvas {
  //   width: 60vw !important;
  //   height: 100% !important;
  // }
  .filters-background {
    top: 48px;
    background-image: linear-gradient(
      transparent 0%,
      rgba(71, 94, 71, 0.432) 90%
    );
    background-size: 1000px 4px;
    z-index: 10;
  }
  .timelineBox {
    width: 100%;
    img {
      width: 60%;
    }
  }

  .timeline-event-copy {
    width: 40%;
  }
}
