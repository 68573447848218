/* 定义 @font-face 规则来引入 OTF 字体 */
@font-face {
  font-family: "HarmonyBold";
  src: url("./HarmonyOS_Sans_SC_Bold.ttf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "HarmonyRegular";
  src: url("./HarmonyOS_Sans_SC_Regular.ttf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "HarmonyLight";
  src: url("./HarmonyOS_Sans_SC_Light.ttf") format("opentype");
  font-weight: 200;
}

/* 创建 CSS 类来使用不同的字体 */
.fontThin {
  font-family: "HarmonyLight", Arial, sans-serif;
}

.fontNormal {
  font-family: "HarmonyRegular", Arial, sans-serif;
}

.fontBold {
  font-family: "HarmonyBold", Arial, sans-serif;
}
