$white: #fff;
$black: #121212;
$gray: #807f83;
$glitch-red: #ff536b;
$glitch-blue: #3ffeff;

.glitchBtn-2 {
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  font-weight: 500;
  border: 1px solid $black;
  line-height: 1.5;
  font-size: em(14px);
  padding: 0.4rem 1.2rem;
  // width: 100%;
  display: block;
  cursor: pointer;
  position: relative;
  background-color: $white;
  overflow: hidden;
  outline: none;
  text-decoration: none;
  text-align: center;
  vertical-align: bottom;

  &:focus {
    outline: none;
  }
}

.btn-clear {
  background-color: transparent;

  &:hover {
    background-color: $black;
    color: $white;
  }

  &.btn-light {
    border-color: $white;
    color: $white;

    &:hover {
      color: $black;
      background-color: $white;
    }
  }
}

.btn-light {
  &:hover {
    background-color: $black;
    color: $white;
  }
}

.btn-dark {
  background-color: $black;
  color: $white;
  border-color: $white;

  &:hover {
    color: $gray;
  }
}

.btn-glitch-2 {
  // Glitch stuff
  transform: translateZ(0);
  overflow: visible;

  &.btn-glitch-active-2 {
    -webkit-filter: url("#filter2");
    filter: url("#filter2");

    &::after,
    &::before {
      content: "";
      width: 1px;
      position: absolute;
      top: -1px;
      bottom: -1px;
    }

    &::after {
      left: -2px;
      background-color: $glitch-red;
      animation: colorshift-red 2.6s infinite;
      animation-timing-function: step-end;
    }

    &::before {
      right: -2px;
      background-color: $glitch-blue;
      animation: colorshift-blue 2.6s infinite;
      animation-timing-function: step-end;
    }
  }
}

.dark {
  background-color: $black;
}

@keyframes colorshift-red {
  0%,
  7% {
    background-color: $glitch-red;
  }
  8%,
  18% {
    background-color: transparent;
  }
  19% {
    background-color: $glitch-red;
    width: 2px;
  }
  23%,
  100% {
    background-color: transparent;
  }
}

@keyframes colorshift-blue {
  0%,
  7% {
    background-color: $glitch-blue;
  }
  8%,
  18% {
    background-color: transparent;
  }
  19% {
    background-color: $glitch-blue;
    width: 2px;
  }
  23%,
  100% {
    background-color: transparent;
  }
}
