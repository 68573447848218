@font-face {
  font-family: "iconfont"; /* Project id 4610648 */
  src: url('iconfont.woff2?t=1723616502573') format('woff2'),
       url('iconfont.woff?t=1723616502573') format('woff'),
       url('iconfont.ttf?t=1723616502573') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-jiantou1:before {
  content: "\e63c";
}

.icon-a-5:before {
  content: "\e63d";
}

.icon-a-2:before {
  content: "\e63e";
}

.icon-a-3:before {
  content: "\e63f";
}

.icon-a-6:before {
  content: "\e640";
}

.icon-a-4:before {
  content: "\e641";
}

.icon-a-1:before {
  content: "\e642";
}

.icon-jiantou2:before {
  content: "\e643";
}

.icon-bilibili:before {
  content: "\e63b";
}

.icon-jiantou:before {
  content: "\e63a";
}

.icon-a-ziyuan2:before {
  content: "\e639";
}

.icon-a-ziyuan18:before {
  content: "\e638";
}

.icon-a-ziyuan224:before {
  content: "\e635";
}

.icon-a-ziyuan226:before {
  content: "\e636";
}

.icon-a-ziyuan225:before {
  content: "\e637";
}

.icon-a-ziyuan9:before {
  content: "\e62b";
}

.icon-a-ziyuan11:before {
  content: "\e62c";
}

.icon-a-ziyuan10:before {
  content: "\e62d";
}

.icon-a-ziyuan8:before {
  content: "\e62e";
}

