@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./font/index.css");
html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
html.lenis {
  height: auto;
}
body {
  background: #000;
  color: #fff;
  box-sizing: border-box;
  font-family: "HarmonyRegular", Arial, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* cursor: none; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

a {
  pointer-events: all;
  /* color: black; */
  text-decoration: none;
}
i {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

svg {
  fill: black;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.curzr-arrow-pointer {
  position: relative;
  z-index: 99;
}
canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 5s ease 1s forwards;
}
/* 滚动条 */
::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
}
.test {
  border: 1px solid red;
}
.h100 {
  height: calc(100svh - 48px);
}
@media (min-width: 1024px) {
  .h100 {
    height: calc(100svh - 66px);
  }
}
.border {
  box-shadow: 0 0 0 0.5px #fff, 0 0 0 -0.5px #fff, 0.2px 0 0 0 #fff inset,
    -0.2px 0 0 0 #fff inset;
  border: none;
  transition: box-shadow 0.3s ease-in-out; /* 添加动画效果 */
}

.border-bold {
  box-shadow: 0 0 0 1px #fff, 0 0 0 -1px #fff, 1px 0 0 0 #fff inset,
    -1px 0 0 0 #fff inset;
}
.border-x {
  box-shadow: 0.5px 0 0 0 #fff, -0.5px 0 0 0 #fff;
  border: none;
}

/* Y方向边框（顶部和底部） */
.border-y {
  box-shadow: 0 0.5px 0 0 #fff, /* 顶部边框 */ 0 -0.5px 0 0 #fff; /* 底部边框 */
  border: none;
}
.border-y:hover {
  box-shadow: 0 1px 0 0 #fff, 0 -1px 0 0 #fff;
}
/* 顶部边框 */
.border-t {
  box-shadow: 0 0.5px 0 0 #fff;
  border: none;
}

/* 底部边框 */
.border-b {
  box-shadow: 0 0.5px 0 0 #fff;
  border: none;
}

/* 左边框 */
.border-l {
  box-shadow: 0.5px 0 0 0 #fff;
  border: none;
}

/* 右边框 */
.border-r {
  box-shadow: -0.5px 0 0 0 #fff;
  border: none;
}

.slogan {
  width: 100%;
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  transition: all 0.2s;
  text-align: center;
  color: transparent;
  font-weight: 600;
  -webkit-text-stroke: 4px #fff;
  text-shadow: 4px 4px 0px #000000, 6px 6px 0px #ffffff, 9px 9px 0px #000000;
  /* text-shadow: 10px 10px 0px #07bccc, 15px 15px 0px #e601c0,
    20px 20px 0px #e9019a, 25px 25px 0px #f40468, 45px 45px 10px #482896; */
  cursor: pointer;
  text-align: center;
}
.beast {
  transition: all 0.2s;
  color: #ffffff;
  font-weight: 600;
  /* -webkit-text-stroke: 4px #fff; */
  text-shadow: 4px 4px 0px #000000, 6px 6px 0px #ffffff, 9px 9px 0px #000000;
}
.slogan:hover {
  font-variation-settings: "wght" 100, "ital" 0;
  text-shadow: none;
}
.beast:hover {
  font-variation-settings: "wght" 100, "ital" 0;
  text-shadow: none;
}

.cursor {
  display: flex;
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  mix-blend-mode: exclusion;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  opacity: 0;
  will-change: transform;
}

.cursor-outline {
  opacity: 0;
  display: flex;
  z-index: 998;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  mix-blend-mode: multiply;
  transition: all 0.5s ease-out;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  will-change: transform;
  background: rgb(210, 180, 12);
}

.line-y {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  will-change: width;
}
.line-x {
  position: absolute;
  top: 0;
  left: 0;
  height: 0%;
  will-change: height;
}
.line-b {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0%;
  will-change: width;
}
.line-t {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  will-change: width;
}

.line-r {
  position: absolute;
  top: 0;
  right: 0;
  height: 0%;
  will-change: height;
}
.line-l {
  position: absolute;
  top: 0;
  left: 0;
  height: 0%;
  will-change: height;
}
.animated-text {
  transition: transform 0.2s ease-out;
  will-change: transform;
}
.animated-text:hover {
  transform: scale(1.1);
}

.blur-color {
  background: rgba(0, 8, 24, 0.651);
}
.light-text-color {
  color: #f7f8f871;
}

.logo {
  width: 300px;
  height: 120px;
}

.link-text {
  position: relative;
  /* font-size: 25px; */
  /* font-weight: 600; */
  height: 1.5em;
  line-height: 1.5em;
  cursor: pointer;
  background-color: inherit;
  border: none;
  outline: none;
  color: #f1f1f1;
  transition: all 0.3s;
}

.link-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 2px;
  width: 100%;
  background-color: #f1f1f1;
  z-index: -1;
  border-radius: 5px;
  transition: all 0.3s;
}
.link-text:hover {
  color: #000;
}
.link-text:hover::after {
  height: inherit;
  width: 120%;
  top: 50%;
}

.border-1px {
  border: 1px solid #fff;
}
